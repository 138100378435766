<template>
    <div class="examPaperTest">
        <div class="header">
            <div class="header_logo flex-align-between">
                <img class="ssyz_logo" src="../../assets/login/logo2.png" alt="">
                <div class="goBack flex-align">
                    <img src="../../assets/copybook/AIreport/return.png" alt="" @click="goBack">
                    <span @click="goBack">返回</span>
                </div>
            </div>
            <div class="generateDate">
                <span>学期：{{ termName }}</span>
                <span>报告时间：{{ createTime }}</span>
            </div>
        </div>
        <template>
            <!-- <div class="navigation">
                <ul class="flex-column-center">
                    <li :class="item == 'line' ? 'bg3' : anchorPointIndex === index ? 'bg1' : 'bg2'"
                        v-for="(item, index) in ($route.query.writeCount == 0 ? anchorPointArr2 : anchorPointArr)"
                        @click="item != 'line' ? switchclick(index) : ''">
                        <span v-if="item != 'line'">{{ item }}</span>
                        <i class="el-icon-arrow-right" v-if="item != 'line'"></i>
                    </li>
                </ul>
            </div> -->
            <!--  v-if="$route.query.writeCount != 0" -->
            <div class="box flex-column-center box1">
                <div class="EvaluationTitle">{{ $route.query.bindUserName }}作品测评报告</div>
                <div class="content comprehensiveEvaluation">
                    <div class="title">
                        <img class="icon1" src="../../assets/copybook/AIreport/evaluate.png" alt="">
                        <span class="titleText">综合评价</span>
                    </div>
                    <div class="gradeBox flex-column-center" v-if="false">
                        <div class="gradeBox_content flex-align">
                            <div class="grade flex-align-center" v-for="(i, index) in 4" :key="index">
                                <img src="../../assets/copybook/AIreport/girl.png" alt="" v-if="i == integrationLevel">
                            </div>
                        </div>
                        <div class="gradeBox_bg"></div>
                        <div class="gradeBox_content flex-align">
                            <div class="grade_text flex-align-center" :class="`grade_text${index + 1}`"
                                v-for="(item, index) in gradeTextArr" :key="index">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="evaluationDimension">
                        <div class="comment-box flex-align-between">
                            <template v-for="(item, index) in commentList">
                                <div class="comment-item " :style="{ backgroundImage: 'url(' + item.url + ')' }"
                                    v-if="item.show">
                                    <img src="@/assets/assistant/praise.png" alt="" v-if="item.score >= 85">
                                    <span class="flex-align-center" v-if="!isEnd">测评中</span>
                                    <span class="flex-align-center" v-else>{{ computeLevel(item.score) }}</span>
                                </div>
                            </template>
                        </div>
                        <!-- <div class="all_table" v-if="tableData && tableData.length > 0 && $route.query.writeCount != 0">
                            <el-table :data="tableData" style="width: 100%" border :span-method="arraySpanMethod"
                                :cell-style="cellStyle">
                                <el-table-column v-for="(col, index) in columns" :key="index" :label="col.label"
                                    :label-class-name="col.className" align="center"
                                    v-if="tableData[0][col.field] != null && tableData[0][col.field] > 0">
                                    <template #default="{ row }">
                                        {{ computeLevel(row[col.field]) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="box flex-align-center box3">
                <div class="content overallEvaluation">
                    <div class="title">
                        <img class="icon2" src="../../assets/copybook/AIreport/Comment.png" alt="">
                        <span class="titleText">整体测评</span>
                        <span class="subtitle">（点击单字即可查看单字点评）</span>
                    </div>
                    <div class="container flex-column-center">
                        <div class="containerC flex-align-between">
                            <div class="prev carousel" :class="{ prevAff: prevType }" @click="slidePre">
                            </div>
                            <div class="carouselMian">
                                <slider ref="slider" :options="sliderinit" @slide="slide">
                                    <slideritem v-for="(item, index) in wordList" :key="index">
                                        <div class="textbox flex-column-center"
                                            :class="{ textSonAff: appraisalIindex == index, }" @click="switchover2(index)">
                                            <div class="">
                                                <div class="textSonT">
                                                    <img src="../../assets/assistant/tian.png" />
                                                    <img :src="item.resFilename ? item.resFilename : item.imagePath" />
                                                </div>
                                                <div class="textSonImg flex-align-between">
                                                    <div class="imgbox"
                                                        v-for="(itemGrandson, indexGrandson) in starList[wordList[index].starLevel]"
                                                        :key="indexGrandson">
                                                        <img :src="itemGrandson" />
                                                    </div>
                                                </div>
                                            </div>
                                            <el-link type="primary" class="view" :underline="false"
                                                @click="switchover(index), commenVisible = true, openScreen()">查看</el-link>
                                        </div>

                                    </slideritem>
                                </slider>
                            </div>
                            <div class="next carousel" :class="{ nextAff: nextType && this.wordList.length > 5, }"
                                @click="slideNext"></div>
                        </div>
                        <div class="focus flex-align">
                            <div :class="{ focusColor: focusIndex == index }" v-for="(item, index) in focusNum" :key="index"
                                @click="slideTo(index)">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="box flex-align-center box5">
                <div class="content singleWordReview">
                    <div class="explaititle flex-align-between">
                        <div class="title">
                            <img class="icon3" src="../../assets/copybook/AIreport/Evaluation.png" alt="">
                            <span class="titleText">单字点评</span>
                        </div>
                        <div id="myElement" class="aWordexplain flex-align" @click="commenVisible = true, openScreen()">
                            <span>单字讲解模式</span>
                            <img src="../../assets/assistant/aWordexplain.png" alt="">
                        </div>
                    </div>
                    <div class="aWorddetails">
                        <div class="imageText flex-align-around">
                            <div class="imageTextBox">
                                <span>原字</span>
                                <div class="multilayer">
                                    <img src="../../assets/assistant/tian.png" />

                                    <img :src="wordCover" alt="">
                                </div>
                            </div>
                            <div class="imageTextBox">
                                <span>待测字</span>
                                <div class="multilayer">
                                    <img src="../../assets/assistant/tian.png" />
                                    <img :src="resFilename" alt="">
                                </div>
                            </div>
                            <div class="imageTextBox" v-if="orderStrokes == 1">
                                <span>AI智能比对(拉伸后)</span>
                                <div class="multilayer">
                                    <img src="../../assets/assistant/tian.png" />
                                    <img :src="wordSkillCover" alt="">
                                    <img :src="wordShadowCover" alt="">
                                    <img :src="imagePath" alt="">

                                </div>
                            </div>
                            <div class="imageTextBox">
                                <span>AI智能比对(原作品)</span>
                                <div class="multilayer" v-if="orderStrokes == 1">
                                    <img src="../../assets/assistant/tian.png" />
                                    <img :src="wordSkillCover" alt="">
                                    <img :src="wordShadowCover" alt="">
                                    <img :src="resFilename" alt="">
                                </div>
                                <div class="multilayer" v-else>
                                    <img src="../../assets/assistant/tian.png" />
                                    <img :src="wordSkillCover" alt="">
                                    <img :src="resFilename" alt="">
                                    <img :src="wordShadowCover" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="Dimension">
                            <div class="all_table" v-if="aWord_table && aWord_table.length > 0 && orderStrokes == 1">
                                <transition name="fade">
                                    <div class="all_table2" v-if="aWord_table && aWord_table.length > 0">
                                        <el-table :data="aWord_table" style="width: 100%" border
                                            :span-method="arraySpanMethod" :cell-style="cellStyle">
                                            <el-table-column v-for="(col, index) in columns" :key="index" :label="col.label"
                                                :label-class-name="col.className" align="center"
                                                v-if="aWord_table[0][col.field] != null && aWord_table[0][col.field] > 0">
                                                <template #default="{ row }">
                                                    {{ computeLevel(row[col.field]) }}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <div class="comment" v-if="orderStrokes == 1">
                            <div class="comment_title">评语</div>
                            <div class="comment_content">
                                <ul>
                                    <li v-for="(item, index) in  remark" v-highlight="{ value: vocabulary }">{{ item.sug }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div> -->
            <div class="commentExplain">
                <el-dialog width="100%" :visible.sync="commenVisible && aWord_table2.length > 0" center :show-close="false"
                    :modal-append-to-body="false">

                    <div class="commentImgbox">
                        <div class="closedom">
                            <i class="el-icon-circle-close" @click="commenVisible = false, openScreen()"></i>
                        </div>
                        <div class="Explain_prev ExplainImg"
                            :class="{ 'Explain_prev_centre': character ? appraisalIindex != 0 : UserhistoryIndex > 0 }"
                            @click="Explainswitch('prev')"></div>
                        <div class="Explain_next ExplainImg"
                            :class="{ 'Explain_next_centre': character ? appraisalIindex != wordList.length - 1 : UserhistoryIndex < UserhistoryList_container.length - 6 }"
                            @click="Explainswitch('next')"></div>
                        <div class="commentExplainTitle flex-align-center">
                            <!-- <div class="Explain_switch"></div> -->
                            <div class="commentExplainTitle_text">讲解模式</div>
                            <!-- <div class="Explain_switch flex-align-center">
                                <div @click="character = true"
                                    v-if="$route.query.bindUserName && $route.query.bindUserId != 0">单字测评</div>
                                <div @click="character = false, getUserhistory()"
                                    v-if="$route.query.bindUserName && $route.query.bindUserId != 0">历史测评对比图</div>
                            </div> -->
                        </div>
                        <div class="Explain_switch flex-align-center">
                            <div @click="showWeidu = false, currentName = '', character = true" class="flex-align">
                                <img src="@/assets/assistant/view.png" alt="">
                                查看全图
                            </div>
                            <div @click="character = false, getUserhistory()"
                                v-if="$route.query.bindUserName && $route.query.bindUserId != 0">历史测评对比图</div>
                        </div>
                        <div class="switch_box">
                            <template v-if="character">
                                <div class="commentTable">
                                    <!-- <transition name="fade"> -->
                                        <!-- v-if="aWord_table && aWord_table.length > 0 && orderStrokes == 1" -->
                                    <div class="all_table3">
                                        <el-table :data="aWord_table2" style="width: 100%" border
                                            :span-method="arraySpanMethod" :cell-style="cellStyle" :key="Math.random()">
                                            <el-table-column v-for="(col, index) in currentWordList" :key="Math.random()"
                                                :label="col.label" :label-class-name="getLabelClassName(col, index)"
                                                align="center" v-if="col.show">
                                                <span style="display:block;width:100%;height: 100%;"
                                                    v-if="col.score == 0 || col.score === null" class="flex-align-center"
                                                    @click="selectWeidu(col)">
                                                    <i class="el-icon-location" style="color: #00b68e;"
                                                        v-show="currentName == col.name"></i>
                                                    测评中
                                                </span>
                                                <span v-else style="display:block;width:100%;height: 100%;"
                                                    class="flex-align-center" @click="selectWeidu(col)">
                                                    <i class="el-icon-location" style="color: #00b68e;"
                                                        v-show="currentName == col.name"></i>
                                                    {{ computeLevel(Number(col.score)) }}
                                                </span>
                                            </el-table-column>
                                        </el-table>
                                        <!-- <el-table :data="aWord_table2" style="width: 100%" border
                                            :span-method="arraySpanMethod" :cell-style="cellStyle">
                                            <el-table-column v-for="(col, index) in columns" :key="index" :label="col.label"
                                                :label-class-name="col.className" align="center" v-if="col.visible">
                                                <template #default="{ row }">
                                                    <div v-if="row[col.field].type === 0">
                                                        {{ computeLevel(row[col.field].value) }}
                                                    </div>
                                                    <div
                                                        v-else-if="row[col.field].type === 1 && row[col.field].value.length > 0">
                                                        <span v-for="(item, index) in row[col.field].value" :key="index">{{
                                                            item
                                                        }}{{ index !== row[col.field].value.length - 1 ? '，' : ''
}}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table> -->
                                    </div>
                                    <!-- </transition> -->
                                </div>
                                <div class="illustration" v-if="showWeidu">
                                    <!-- <div class="illustration_img2">
                                        <img src="../../assets/assistant/tian.png" />
                                        <img class="ossPath" :src="currentList[currentIndex].ossPath" alt=""
                                            v-if=currentList.length>0>
                                        <img :src="wordShadowCover" alt="">
                                        <img :src="resFilename" alt="">
                                    </div>
                                    <el-button-group v-if="currentList.length > 1">
                                        <el-button type="primary" icon="el-icon-arrow-left" @click="prev"
                                            :disabled="currentIndex == 0">上一页</el-button>
                                        <el-button type="primary" @click="next"
                                            :disabled="currentIndex == currentList.length - 1">下一页<i
                                                class="el-icon-arrow-right el-icon--right"></i></el-button>
                                    </el-button-group> -->

                                    <div class="illustration_img2">
                                        <div class="banner-box">
                                            <el-carousel :autoplay="false" arrow="never">
                                                <el-carousel-item v-for="(item, index) in currentList" :key="index"
                                                    arrow="never">
                                                    <img :src="bottomImg" />
                                                    <img class="mi-box" src="../../assets/assistant/tian.png" />
                                                    <img :src="wordShadowCover" alt="">
                                                    <img :src="resFilename" alt="">
                                                    <img class="ossPath" v-if="currentList.length > 0" :src="item.ossPath"
                                                        alt="">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                    </div>

                                </div>
                                <div class="illustration" v-else>
                                    <div class="illustration_img2" v-if="orderStrokes == 1">
                                        <div class="banner-box">
                                            <img src="../../assets/assistant/tian.png" />
                                            <img :src="wordSkillCover" alt="">
                                            <img :src="wordShadowCover" alt="">
                                            <img :src="resFilename" alt="">
                                        </div>
                                    </div>
                                    <div class="illustration_img2" v-else>
                                        <div class="banner-box">
                                            <img src="../../assets/assistant/tian.png" />
                                            <img :src="wordSkillCover" alt="">
                                            <img :src="resFilename" alt="">
                                            <img :src="wordShadowCover" alt="">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="illustration flex-align-between " v-else>
                                    <div class="illustration_img" v-if="orderStrokes == 1">
                                        <img src="../../assets/assistant/tian.png" />
                                        <img :src="wordSkillCover" alt="">
                                        <img :src="wordShadowCover" alt="">
                                        <img :src="resFilename" alt="">
                                    </div>
                                    <div class="illustration_img" v-else>
                                        <img src="../../assets/assistant/tian.png" />
                                        <img :src="wordSkillCover" alt="">
                                        <img :src="resFilename" alt="">
                                        <img :src="wordShadowCover" alt="">

                                    </div>
                                    <div class="illustration_text">
                                        <ul>
                                            <li v-for="(item, index) in  remark" v-highlight="{ value: vocabulary }">{{
                                                item.sug
                                            }}
                                            </li>
                                        </ul>
                                    </div>
                                </div> -->
                            </template>
                            <template v-else>
                                <div class="history_legend flex-column-center">
                                    <div class="history_legend_school year flex-align-between" v-if="true">
                                        <div class="history_select"></div>
                                        <div class="history_title">{{ $route.query.bindUserName }}的历史测评对比图</div>
                                        <div class="history_select">
                                            <el-select v-model="termValue" placeholder="请选择" :popper-append-to-body="false"
                                                @change="termChange">
                                                <el-option v-for="item in termList" :key="item.key" :label="item.label"
                                                    :value="item.termName">
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </div>

                                    <div class="history_legend_content flex-align-around" v-if="UserhistoryList.length != 0"
                                        :class="history_legend_style[UserhistoryList.length]">
                                        <div class="sonbox" v-for="(item, index) in UserhistoryList" :key="index">
                                            <RadarEvaluation class="radar_evaluation" :UserhistoryList="item"
                                                :IndexNum="UserhistoryList.length"></RadarEvaluation>
                                        </div>
                                    </div>
                                    <div class="history_legend_content flex-align-around" v-else>
                                        <span class="empty">暂无数据...</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>

                </el-dialog>
            </div>
        </template>
    </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider";
import RadarEvaluation from './radar_Evaluation.vue'
export default {
    //import引入组件才能使用
    components: {
        slider,
        slideritem,
        RadarEvaluation,
    },
    props: {},
    data() {
        return {
            termName: null,
            anchorPointIndex: 0,
            anchorPointArr: [
                '综合评价',
                'line',
                '整体测评',
                'line',
                '单字点评',
            ],
            anchorPointArr2: [
                '整体测评',
                'line',
                '单字点评',
            ],
            gradeTextArr: [
                '待提升', '合格', '良好', '优秀'
            ],
            tableData: [],
            createTime: null,
            integrationLevel: null,
            //文字轮播--整体测评
            prevType: false,
            nextType: false,
            sliderinit: {
                currentPage: 0,
                tracking: false,
                thresholdDistance: 100,
                thresholdTime: 300,
                infinite: 5,
                slidesToScroll: 5,
                freeze: true, //禁止拖动
            },
            wordList: [],
            appraisalIindex: 0,
            starList: {
                0: [
                    require("../../assets/assistant/starC.png"),
                    require("../../assets/assistant/starC.png"),
                    require("../../assets/assistant/starC.png"),
                    require("../../assets/assistant/starC.png"),
                    require("../../assets/assistant/starC.png"),
                ],
                1: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                ],
                2: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                ],
                3: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                ],
                4: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starA.png"),
                ],
                5: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                ],
            },
            focusNum: 0,
            focusIndex: 0,
            //原图
            resFilename: null,
            //拉伸图
            imagePath: null,
            //字图   
            wordCover: null,
            //阴影图
            wordShadowCover: null,
            //窍门图
            wordSkillCover: null,
            aWord_table: [],
            aWord_table2: [],
            columns: [
                {
                    label: '居中占位',
                    className: 'header_lightgreen',
                    field: 'centerPos',
                    visible: false,
                },
                {
                    label: '占格大小',
                    className: 'header_green',
                    field: 'gridSize',
                    visible: false,
                },
                {
                    label: '字形重心',
                    className: 'header_lightgreen',
                    field: 'glyphGrav',
                    visible: false,
                },
                {
                    label: '组合关系',
                    className: 'header_green',
                    field: 'comboRel',
                    visible: false,
                },
                {
                    label: '收放关系',
                    className: 'header_lightgreen',
                    field: 'rewindRel',
                    visible: false,
                },
                {
                    label: '多画等距',
                    className: 'header_green',
                    field: 'multiDist',
                    visible: false,
                },
                {
                    label: '笔画关系',
                    className: 'header_lightgreen',
                    field: 'strokeRel',
                    visible: false,
                },
            ],
            remark: null,
            commenVisible: false,
            Explain_prev_state: false,
            Explain_next_state: false,
            pollingInterval: null,
            // examName: null,
            getWordEvalType: true,
            vocabulary: [
                '居中占位',
                '占格大小',
                '字形重心',
                '收放关系',
                '多画等距',
                '笔画关系',
                '组合关系',
            ],
            wordId: null,
            schoolId: sessionStorage.getItem('schoolId'),
            orderStrokes: null,
            character: true,
            termList: [],
            termValue: '',
            termId: '',
            UserhistoryList: [],
            UserhistoryList_container: [],
            UserhistoryIndex: 0,
            history_legend_style: {
                '1': 'history_legend_content1',
                '2': 'history_legend_content2',
                '3': 'history_legend_content3',
                '4': 'history_legend_content4',
                '5': 'history_legend_content4',
                '6': 'history_legend_content4',
            },
            columns2: [
                {
                    label: '整体',
                    className: 'header_lightgreen',
                    field: 'centerPos',
                    visible: false,
                },
                {
                    label: '局部',
                    className: 'header_green',
                    field: 'gridSize',
                    visible: false,
                },
                {
                    label: '笔画',
                    className: 'header_green',
                    field: 'comboRel',
                    visible: false,
                },
                {
                    label: '字形重心',
                    className: 'header_lightgreen',
                    field: 'glyphGrav',
                    visible: false,
                },

            ],
            commentList: [
                { score: -1, url: require("../../assets/assistant/zhange.png"), show: true },
                { score: -1, url: require("../../assets/assistant/jiegou.png"), show: true },
                { score: -1, url: require("../../assets/assistant/bihua.png"), show: true },
                { score: -1, url: require("../../assets/assistant/zhongxin.png"), show: true },
            ],
            currentWordList: [
                { score: -1, url: require("../../assets/assistant/zhange.png"), show: true, name: 'gridSize', label: '占格', className: 'header_lightgreen' },
                { score: -1, url: require("../../assets/assistant/jiegou.png"), show: true, name: 'structure', label: '结构', className: 'header_green' },
                { score: -1, url: require("../../assets/assistant/bihua.png"), show: true, name: 'strokeRel', label: '笔画', className: 'header_lightgreen' },
                { score: -1, url: require("../../assets/assistant/zhongxin.png"), show: true, name: 'glyphGrav', label: '重心', className: 'header_green' },
            ],
            gridSizeList: [],
            structureList: [],
            strokeRelList: [],
            glyphGravList: [],
            currentIndex: 0,
            currentList: [],
            showWeidu: false,
            currentName: 'gridSize',
            bottomImg: '',
            isEnd: false,
            wordInterval: null
        };
    },
    directives: {
        highlight: {
            inserted: function (el, binding, vnode) {
                let vocabulary = binding.value.value;
                let text = el.textContent;
                let includedElements = vocabulary.filter(item =>
                    text.includes(item)
                );
                for (let index = 0; index < includedElements.length; index++) {
                    const element = includedElements[index];
                    const pattern = new RegExp(element);
                    text = text.replace(pattern, `<span style="color:#01B58C;font-weight: bold;">${element}</span>`);
                }
                el.innerHTML = text;
            }
        }
    },
    // 计算属性
    computed: {
        // 计算属性，用于确定列是否可见
        columnVisibility() {
            return this.columns.map(col => {
                const firstRow = this.aWord_table2[0];
                if (!firstRow) return false;
                return firstRow[col.field] && firstRow[col.field].value != null && firstRow[col.field].value > 0;
            });
        },
    },
    // 监听data中的数据变化
    watch: {
        wordList: {
            handler(newList) {
                for (let i = 0; i < newList.length; i++) {
                    if (newList[i].id === this.wordId) {
                        this.appraisalIindex = i;
                    }
                }
            },
            deep: true,
        },
    },
    // 方法集合
    methods: {
        getqueryTermList() {
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    this.termName = res.data[0].termName;
                    this.termList = res.data;
                    this.termValue = this.termList[0].termName;
                    this.termId = this.termList[0].key;
                })
                .catch(err => {
                    // console.log('添加上课记录失败:', err)
                })
        },
        termChange(value) {
            this.termId = this.termList.find(option => option.termName === value).key;
            this.getUserhistory();
        },
        // 整体数据
        async getreport() {
            let data = {
                uploadId: this.$route.query.reportId,
            };
            let resData = await this.$Api.Myclass.getWordReportEval(data);
            console.log('综合评价数据:', resData);
            if (!resData || !resData.data.wordList) {
                return;
            }
            //维度
            this.tableData = [
                {
                    'centerPos': resData.data.centerPos, // 居中占位
                    'gridSize': resData.data.gridSize, // 占格大小
                    'glyphGrav': resData.data.glyphGrav,// 字形重心
                    'rewindRel': resData.data.rewindRel,// 收放关系
                    'multiDist': resData.data.multiDist,// 多画等距
                    'strokeRel': resData.data.strokeRel,// 笔画关系
                    'comboRel': resData.data.comboRel,// 组合关系
                }
            ];
            // 综合评价维度(改版后)
            // 计算结构分数
            let structureScore;
            if (resData.data.comboRel > -1) {
                structureScore = resData.data.comboRel
            } else {
                // 独体字不显示成绩
                this.$set(this.commentList, 1, { ...this.commentList[1], show: false });
            }
            // 计算笔画分数
            let strokeScore;
            if (resData.data.grade) {
                if (resData.data.grade <= 2) {
                    if (resData.data.multiDist <= 0) {
                        strokeScore = resData.data.rewindRel * 0.4 + resData.data.strokeRel * 0.6
                    } else {
                        strokeScore = resData.data.rewindRel * 0.3 + resData.data.strokeRel * 0.5 + resData.data.multiDist * 0.2
                    }
                } else if (resData.data.grade > 4) {
                    if (resData.data.multiDist <= 0) {
                        strokeScore = resData.data.rewindRel * 0.6 + resData.data.strokeRel * 0.4
                    } else {
                        strokeScore = resData.data.rewindRel * 0.5 + resData.data.strokeRel * 0.3 + resData.data.multiDist * 0.2
                    }
                } else {
                    if (resData.data.multiDist <= 0) {
                        strokeScore = resData.data.rewindRel * 0.5 + resData.data.strokeRel * 0.5
                    } else {
                        strokeScore = resData.data.rewindRel * 0.4 + resData.data.strokeRel * 0.4 + resData.data.multiDist * 0.2
                    }
                }
            } else {
                if (resData.data.multiDist <= 0) {
                    strokeScore = resData.data.rewindRel * 0.6 + resData.data.strokeRel * 0.4
                } else {
                    strokeScore = resData.data.rewindRel * 0.5 + resData.data.strokeRel * 0.3 + resData.data.multiDist * 0.2
                }

            }
            this.$set(this.commentList, 0, { ...this.commentList[0], score: (resData.data.gridSize * 0.6) + (resData.data.centerPos * 0.4) });
            this.$set(this.commentList, 1, { ...this.commentList[1], score: structureScore });
            this.$set(this.commentList, 2, { ...this.commentList[2], score: strokeScore });
            this.$set(this.commentList, 3, { ...this.commentList[3], score: resData.data.glyphGrav });
            // this.examName = resData.data.examName;
            //创建时间
            this.createTime = resData.data.createTime;
            // 综合评价等级
            this.integrationLevel = resData.data.integrationLevel;
            //整体测评
            this.wordList = resData.data.wordList;
            this.focusNum = Math.ceil(this.wordList.length / 5);
            // if (this.getWordEvalType) {
            //     this.getWordEval(this.wordList[0].id);
            //     this.wordId = this.wordList[0].id;
            //     this.orderStrokes = this.wordList[0].wordType;
            //     this.getWordEvalType = false;
            // }

            if (resData.data.isEnd) {
                this.isEnd = true;
                this.stopPolling();
            }

        },
        async getWordEval(id) {
            this.wordId = id;
            this.remark = null;
            this.aWord_table = [];
            this.currentName = '';
            this.showWeidu = false;
            let data = {
                wordTestId: id
            };
            let resData = await this.$Api.Myclass.getWordEval(data);
            if (resData.data.integrationScore) {
                clearInterval(this.wordInterval);
                this.wordInterval = null
            } else {
                if (this.wordInterval) {
                    console.log('当前有定时器正在执行');
                    clearInterval(this.wordInterval);
                    this.wordInterval = null;
                    // 启动循环定时器
                    this.wordInterval = setInterval(() => {
                        this.getWordEval(id);
                    }, 5000)
                } else {
                    // 启动循环定时器
                    this.wordInterval = setInterval(() => {
                        this.getWordEval(id);
                    }, 5000)
                }
            }


            this.resFilename = resData.data.resFilename;
            this.imagePath = resData.data.imagePath;
            this.wordCover = resData.data.wordCover;
            this.wordShadowCover = resData.data.wordShadowCover;
            this.wordSkillCover = resData.data.wordSkillCover;
            // 讲解模式 获取各维度窍门图
            this.currentIndex = 0;
            this.currentList = [];
            this.strokeRelList = [];
            this.structureList = [];
            this.gridSizeList = [];
            this.glyphGravList = [];
            if (resData.data.wordSkillCoverList.length > 0) {
                console.log('获取单字讲解模式窍门')
                resData.data.wordSkillCoverList.map(item => {
                    if (item.type == 1) {
                        this.strokeRelList.push(item)
                    } else if (item.type == 2) {
                        this.structureList.push(item)
                    } else if (item.type == 3) {
                        this.gridSizeList.push(item)
                    } else if (item.type == 4) {
                        this.glyphGravList.push(item)
                    } else if (item.type == 5) {
                        this.bottomImg = item.ossPath
                    }
                })
                this.currentList = this.gridSizeList;
            } else {
                console.log('该字没有窍门图')
            }
            this.aWord_table = [
                {
                    'centerPos': resData.data.centerPos ?? 0,
                    'gridSize': resData.data.gridSize ?? 0,
                    'glyphGrav': resData.data.glyphGrav ?? 0,
                    'rewindRel': resData.data.rewindRel ?? 0,
                    'multiDist': resData.data.multiDist ?? 0,
                    'strokeRel': resData.data.strokeRel ?? 0,
                    'comboRel': resData.data.comboRel ?? 0,
                }
            ];
            this.aWord_table2 = [
                {
                    'centerPos': {
                        type: 0,
                        value: resData.data.centerPos ?? 0
                    },
                    'gridSize': {
                        type: 0,
                        value: resData.data.gridSize ?? 0
                    },
                    'glyphGrav': {
                        type: 0,
                        value: resData.data.glyphGrav ?? 0
                    },
                    'rewindRel': {
                        type: 0,
                        value: resData.data.rewindRel ?? 0
                    },
                    'multiDist': {
                        type: 0,
                        value: resData.data.multiDist ?? 0
                    },
                    'strokeRel': {
                        type: 0,
                        value: resData.data.strokeRel ?? 0
                    },
                    'comboRel': {
                        type: 0,
                        value: resData.data.comboRel ?? 0
                    },
                },
                // {
                //     'centerPos': {
                //         type: 1,
                //         value: this.Tipsmodify(resData.data.centerPosTips) ?? []
                //     },
                //     'gridSize': {
                //         type: 1,
                //         value: this.Tipsmodify(resData.data.gridSizeTips) ?? []
                //     },
                //     'glyphGrav': {
                //         type: 1,
                //         value: this.Tipsmodify(resData.data.glyphGravTips) ?? []
                //     },
                //     'rewindRel': {
                //         type: 1,
                //         value: this.Tipsmodify(resData.data.rewindRelTips) ?? []
                //     },
                //     'multiDist': {
                //         type: 1,
                //         value: this.Tipsmodify(resData.data.multiDistTips) ?? []
                //     },
                //     'strokeRel': {
                //         type: 1,
                //         value: this.Tipsmodify(resData.data.strokeRelTips) ?? []
                //     },
                //     'comboRel': {
                //         type: 1,
                //         value: this.Tipsmodify(resData.data.comboRelTips) ?? []
                //     },
                // },
            ];
            // 讲解模式 单字维度(新版)
            // 计算结构分数
            let structureScore;
            if (Number(resData.data.comboRel) > -1) {
                structureScore = resData.data.comboRel;
                this.$set(this.currentWordList, 1, { ...this.currentWordList[1], show: true });
            } else {
                // 独体字不显示成绩
                this.$set(this.currentWordList, 1, { ...this.currentWordList[1], show: false });
            }
            // 计算笔画分数
            let strokeScore;
            if (resData.data.grade) {
                if (resData.data.grade <= 2) {
                    if (resData.data.multiDist <= 0) {
                        strokeScore = resData.data.rewindRel * 0.4 + resData.data.strokeRel * 0.6
                    } else {
                        strokeScore = resData.data.rewindRel * 0.3 + resData.data.strokeRel * 0.5 + resData.data.multiDist * 0.2
                    }
                } else if (resData.data.grade > 4) {
                    if (resData.data.multiDist <= 0) {
                        strokeScore = resData.data.rewindRel * 0.6 + resData.data.strokeRel * 0.4
                    } else {
                        strokeScore = resData.data.rewindRel * 0.5 + resData.data.strokeRel * 0.3 + resData.data.multiDist * 0.2
                    }
                } else {
                    if (resData.data.multiDist <= 0) {
                        strokeScore = resData.data.rewindRel * 0.5 + resData.data.strokeRel * 0.5
                    } else {
                        strokeScore = resData.data.rewindRel * 0.4 + resData.data.strokeRel * 0.4 + resData.data.multiDist * 0.2
                    }
                }
            } else {
                if (resData.data.multiDist <= 0) {
                    strokeScore = resData.data.rewindRel * 0.6 + resData.data.strokeRel * 0.4
                } else {
                    strokeScore = resData.data.rewindRel * 0.5 + resData.data.strokeRel * 0.3 + resData.data.multiDist * 0.2
                }

            }
            this.$set(this.currentWordList, 0, { ...this.currentWordList[0], score: (resData.data.gridSize * 0.6) + (resData.data.centerPos * 0.4) });
            this.$set(this.currentWordList, 1, { ...this.currentWordList[1], score: structureScore });
            this.$set(this.currentWordList, 2, { ...this.currentWordList[2], score: strokeScore });
            this.$set(this.currentWordList, 3, { ...this.currentWordList[3], score: resData.data.glyphGrav });

            this.remark = JSON.parse(resData.data.remark);
            this.columnVisibility.forEach((isVisible, index) => {
                this.columns[index].visible = isVisible;
            });

        },
        Tipsmodify(data) {
            if (data == null) return data;
            if (data.length < 1) return data;
            return data.map((item, index) => {
                if (index === 0) {
                    return item;
                } else {
                    return item.substring(2);
                }
            });

        },
        switchclick(index) {
            this.anchorPointIndex = index;
            this.scrollToElement(index);
        },
        scrollToElement(index) {
            let domName = '';
            if (this.$route.query.writeCount == 0) {
                if (index == 0) {
                    domName = '.box3';
                } else {
                    domName = '.box5';
                }

            } else {
                domName = `.box${index + 1}`
            }
            const element = document.querySelector(domName);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            // if (rowIndex === 1) {
            //     if (columnIndex === 0) {
            //         return [1, 8];
            //     }
            // }
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            if (typeof (row.integrity) == 'string') {
                return {
                    'text-align': 'left',
                }
            }
        },
        computeLevel(score) {
            if (score >= 85) {
                return '优秀'
            }
            if (score >= 70 && score < 85) {
                return '良好'
            }
            if (score >= 60 && score < 70) {
                return '合格'
            }
            if (score < 60) {
                return '待提升'
            }
        },
        //测试上下一页
        slideNext() {
            this.$refs.slider.$emit("slideNext");
        },
        //测试上一页
        slidePre() {
            this.$refs.slider.$emit("slidePre");
        },
        slide(data) {
            this.focusIndex = Math.ceil(data.currentPage / 5);
            if (data.currentPage == 0) {
                this.nextType = true;
                this.prevType = false;
            } else if (data.currentPage > this.wordList.length - 5) {
                this.nextType = false;
                this.prevType = true;
            } else {
                this.nextType = true;
                this.prevType = true;
            }
        },
        slideTo(value) {
            this.$refs.slider.$emit("slideTo", value * 5);
        },
        //整体测评点击切换(查看)
        switchover(index) {
            this.appraisalIindex = index;
            console.log('当前索引', this.appraisalIindex);
            this.wordId = this.wordList[index].id;
            this.orderStrokes = this.wordList[index].wordType;
            this.getWordEval(this.wordList[index].id);
        },
        switchover2(index) {
            this.appraisalIindex = index;
            console.log('当前索引', this.appraisalIindex);
            this.wordId = this.wordList[index].id;
            this.orderStrokes = this.wordList[index].wordType;
        },
        Explainswitch(type) {
            if (this.character) {
                if (type == 'prev') {
                    if (this.appraisalIindex > 0) {
                        this.appraisalIindex--;
                        this.switchover(this.appraisalIindex)
                    }
                } else {
                    if (this.appraisalIindex < this.wordList.length - 1) {
                        this.appraisalIindex++;
                        this.switchover(this.appraisalIindex)
                    }
                }
            } else {
                if (type == 'prev') {
                    if (this.UserhistoryIndex > 0) {
                        this.UserhistoryIndex = this.UserhistoryIndex - 6;
                        this.createUserhistoryList();
                    }

                } else {
                    if (this.UserhistoryIndex < this.UserhistoryList_container.length - 6) {
                        this.UserhistoryIndex = this.UserhistoryIndex + 6;
                        this.createUserhistoryList();
                    }
                }

            }


        },
        goBack() {
            this.stopPolling();
            window.close()
        },
        startPolling() {
            this.pollingInterval = setInterval(this.getreport, 5000);
        },
        stopPolling() {
            clearInterval(this.pollingInterval);
        },
        permission() {
            const schoolId = sessionStorage.getItem('schoolId');
            const gradeId = sessionStorage.getItem('gradeId');
            return schoolId === '181195' || (schoolId === '181077' && ['3', '4'].includes(gradeId));
        },
        async getUserhistory() {
            let data = {
                userId: this.$route.query.bindUserId,
                termKey: this.termId,
            };
            let resData = await this.$Api.Myclass.getUserhistory(data);
            this.UserhistoryList_container = resData.data;
            this.UserhistoryList = (this.UserhistoryList_container.slice(0, 6)).sort((a, b) => a.testId - b.testId);

        },
        createUserhistoryList() {
            this.UserhistoryList = (this.UserhistoryList_container.slice(this.UserhistoryIndex, this.UserhistoryIndex + 6)).sort((a, b) => a.testId - b.testId);
        },
        // openScreen() {
        //     if (!document.fullscreenElement) {
        //         // 请求全屏
        //         if (document.documentElement.requestFullscreen) {
        //             document.documentElement.requestFullscreen();
        //         } else if (document.documentElement.mozRequestFullScreen) { // Firefox
        //             document.documentElement.mozRequestFullScreen();
        //         } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        //             document.documentElement.webkitRequestFullscreen();
        //         } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
        //             document.documentElement.msRequestFullscreen();
        //         }
        //     } else {
        //         // 退出全屏
        //         if (document.exitFullscreen) {
        //             document.exitFullscreen();
        //         } else if (document.mozCancelFullScreen) { // Firefox
        //             document.mozCancelFullScreen();
        //         } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        //             document.webkitExitFullscreen();
        //         } else if (document.msExitFullscreen) { // IE/Edge
        //             document.msExitFullscreen();
        //         }
        //     }
        // },
        openScreen() {
            if (!document.fullscreenElement) {
                // 请求全屏
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen();
                } else if (document.documentElement.msRequestFullscreen) {
                    document.documentElement.msRequestFullscreen();
                }
            } else {
                // 退出全屏
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
            // this.handleFullscreenChange();
        },
        addFullScreenListeners() {
            document.addEventListener('fullscreenchange', this.handleFullscreenChange);
            document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange);
            document.addEventListener('mozfullscreenchange', this.handleFullscreenChange);
            document.addEventListener('msfullscreenchange', this.handleFullscreenChange);
        },
        removeFullScreenListeners() {
            document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange);
            document.removeEventListener('msfullscreenchange', this.handleFullscreenChange);
        },
        handleFullscreenChange(event) {
            if (document.fullscreenElement) {
                console.log('进入全屏');
            } else {
                console.log('退出全屏,清除讲解模式定时器');
                this.commenVisible = false;
                if (this.wordInterval) {
                    clearInterval(this.wordInterval);
                    this.wordInterval = null
                }
            }
        },
        prev() {
            if (this.currentIndex == 0) {
                this.$message({
                    message: '当前已经是第一页了',
                    type: 'warning'
                });
            } else {
                this.currentIndex -= 1
            }
        },
        next() {
            if (this.currentIndex == this.currentList.length - 1) {
                this.$message({
                    message: '当前已经是最后一页了',
                    type: 'warning'
                });
                return
            }
            this.currentIndex += 1;
        },
        selectWeidu(item) {
            // console.log(item);
            this.currentName = item.name;
            console.log('this.currentName:', this.currentName)
            this.currentIndex = 0;
            this.showWeidu = true;
            switch (item.name) {
                case 'gridSize':
                    this.currentList = this.gridSizeList;
                    break;
                case 'structure':
                    this.currentList = this.structureList;
                    break;
                case 'strokeRel':
                    this.currentList = this.strokeRelList;
                    break;
                case 'glyphGrav':
                    this.currentList = this.glyphGravList;
                    break;
            }

        },
        getLabelClassName(item, index) {
            let num = 0;
            this.currentWordList.map(item => {
                if (item.show) {
                    num += 1
                }
            })
            if (num == 4) {
                return index % 2 === 0 ? 'header_lightgreen' : 'header_green';
            } else {
                return index % 2 === 0 && index !== 0 ? 'header_lightgreen' : 'header_green';
            }
        }
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.getqueryTermList();
        this.getreport();
        this.startPolling();
        this.addFullScreenListeners();
        // this.getUserhistory();


    },
    beforeDestroy() {
        this.removeFullScreenListeners();
    },

}
</script>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.9s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@import "./less/examPaperTest.less";

.carouselMian {
    height: 140px;
    width: 810px;

    /deep/.slider-wrapper {
        margin: 0 2px;
    }

    /deep/.slider-item {
        width: 112.4px !important;
        height: 135px !important;
        margin-right: 26px;
    }

    /deep/.slider-pagination {
        display: none;
    }

    /deep/.swiper-container-horizontal>*>.slider-pagination-bullet {
        width: 20px !important;
        height: 8px !important;
        border-radius: 14px;
        background-color: #c2c2c2;
    }

    /deep/.swiper-container-horizontal .slider-pagination-bullet-active {
        background-color: #89dcc9;
    }
}
</style>